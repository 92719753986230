<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToHome">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.ACCESS_SOCIOS_REGISTER')}} </p>
            </div>
        </div>

        <div class="container-fluid px-md-1 px-lg-4 px-1 py-5" :style="{padding:'0 0', backgroundImage:`url(${require('@/assets/home-bg.jpg')})`, backgroundSize:'cover', 'background-repeat': 'no-repeat','background-position': 'right', 'min-height':'80vh'}">

            <div class="container bg-white shadow px-sm-0 px-md-5 pt-3">

                <div class="row">
                    <div class="col-12">
                        <p class="spainhn-subtitle">Registro de monumentos</p>
                    </div>
                </div>
                <form class="pt-1 pb-5 mb-5" novalidate  @submit.prevent="validarRegistro">

                    <div class="row">

                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Datos de monumento</p>
                        </div>

                        <div class="col-12">
                            <mdb-input label="Nombre de monumento *" type="text" name="nombreMonumento" v-model="dataForm.monumento" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-container class="">
                                <mdb-select :color="'danger'" label="Tipo de monumento" 
                                    name="tipoMonumento" v-model="tiposDeMonumentos" 
                                    @getValue="(evt) => dataForm.tipo = evt" required outline
                                    validation ref="tipoSelect"
                                    invalidFeedback="Campo obligatorio *"/>
                            </mdb-container>
                        </div>

                        <div class="col-md-4 col-12">
                            <mdb-input label="Año de construcción" type="number" name="constYear" v-model="dataForm.constructionYear" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                    </div>

                    <div class="row w-100 mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Estadísticas</p>
                        </div>
                        
                        <div class="col-md-4 col-6">
                            <mdb-input label="No. Visitas 2019" type="number" name="visitas1" v-model="dataForm.year1" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-4 col-6">
                            <mdb-input label="No. Visitas 2020" type="number" name="visitas2" v-model="dataForm.year2" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-4 col-6">
                            <mdb-input label="No. Visitas 2021" type="number" name="visitas3" v-model="dataForm.year3" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-4 col-6">
                            <mdb-input label="Ticket medio" name="ticketMedio" v-model="dataForm.meanTicket" required outline invalidFeedback="Campo obligatorio *" small="¿De cuánto es el la venta, promedio, por cada ticket?"/>
                        </div>
                    </div>

                    <div class="row w-100 mt-2 gy-0">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Ubicación del monumento</p>
                        </div>

                        <div class="col-12">
                            <mdb-input label="Dirección" type="text" v-model="dataForm.direccion" required outline invalidFeedback="Proporcione una dirección válida"></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Código postal" type="text" size="5" :customValidation="validation.zipCode.validated"
                                :isValid="validation.zipCode.valid" required outline ref="zipCode"
                                v-model="dataForm.codigoPostal" @change="validate('zipCode')"
                                invalidFeedback="Proporcione un Código postal válido"/>
                        </div>

                        <div class="col-md-6 col-12">
                                <!-- <mdb-select label="Comunida autónoma" v-model="comunidadesAutonomas" 
                                    @getValue="(evt) => dataForm.comunidadAutonoma = evt" color="danger"
                                    ref="comunidadSelect"
                                    required outline /> -->
                            <mdb-input v-model="dataForm.comunidadAutonoma" ref="comunidadSelect" required disabled outline label="Comunidad Autónoma"/>
                        </div>


                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <mdb-input v-model="dataForm.provincia" ref="provinciaSelect" required disabled outline label="Provincia"/>
                                </div>
                                <div class="col-md-6 col-12">
                                        <!-- <mdb-select label="Provincia" v-model="provincias" color="danger"
                                            @getValue="(evt) => dataForm.provincia = evt"
                                            ref="provinciaSelect"
                                            required outline /> -->
                                    <mdb-input v-model="dataForm.municipio" ref="municipioSelect" required disabled outline label="Municipio" placeholder=" "/>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 text-start mb-0">
                            <small class="text-muted">Coordenadas 
                                <span>
                                    <font-awesome-icon 
                                        icon="fa-info-circle"
                                        data-mdb-toggle="tooltip" 
                                        data-mdb-placement="right" 
                                        title="Puede encontrar esta información buscando el monumento en google maps">
                                    </font-awesome-icon>
                                </span></small>
                        </div>

                        <google-map @markerAdded="setCoordinates"/>

                        <div class="col-md-6 col-12">
                            <mdb-input label="latitud" type="text" v-model="dataForm.coordenadas.lat" outline required/>
                        </div>
                        <div class="col-md-6 col-12">
                            <mdb-input label="longitud" type="text" v-model="dataForm.coordenadas.lng" outline required/>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Datos de contacto</p>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input type="text" label="Nombre" v-model="dataForm.nombreContacto" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Apellidos" type="text" v-model="dataForm.apellidoContacto" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Teléfono" type="tel" v-model="dataForm.telefonoContacto" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Email" type="email" v-model="dataForm.emailContacto" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Cargo" type="text" v-model="dataForm.cargo" required outline></mdb-input>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-container>
                                <mdb-select label="Horario de contacto" v-model="horariosOptions" ref="horarioSelect"
                                    @getValue="(evt) => dataForm.horarioContacto = evt" color="danger" validation resetBtn
                                    required outline/>
                            </mdb-container>
                        </div>

                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Datos administrativos</p>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input type="email" label="Email" placeholder="Dirección de correo que se utilizará para acceder a su panel de administración" 
                                v-model="dataForm.emailDeAcceso" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Confirma Email" placeholder="Repita la dirección de correo" 
                                type="email" v-model="dataForm.emailDeAccesoConfirmacion" required outline></mdb-input>
                        </div>

                        <div class="col-12 spainhn-divider mb-2 text-start">
                        </div>

                        <div class="col-12">
                            <mdb-input type="textarea" v-model="dataForm.observaciones" outline label="Comentarios"/>
                        </div>

                    </div>

                    <div class="row mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Terminos y condiciones</p>
                        </div>

                        <div class="col-12 text-start mt-2 my-auto">
                            <mdb-input class="my-auto d-flex w-100" type="checkbox" id="terminos-condiciones" 
                                name="terminos-condiciones" v-model="aceptoTerminos" required>
                                <p class="my-auto">Acepto el <span><a href="https://spainheritagenetwork.com/privacidad-cookies">tratamiento de mis datos personales</a></span> para poder acceder al Módulo de Socios*</p>
                            </mdb-input>
                            <small class="text-danger fw-bold" v-bind:class="{ 'd-none':( validation.aceptoTerminos.valid === true ) }">
                                <font-awesome-icon class="inverted" icon="fa-circle-exclamation"></font-awesome-icon>
                                Campo obligatorio*
                            </small>
                        </div>

                    </div>

                    <mdb-btn color="indigo" class="spainhn-button my-2" size="lg" type="submit" :disabled="enviando">Enviar solicitud <span v-bind:class="{'d-none':!enviando}"><b-spinner/></span></mdb-btn>

                </form>

            </div>

        </div>

        <mdb-modal frame position="top" direction="top" :show="alertaModal" @close="alertaModal = false">
            <mdb-modal-body class="text-center">
                <span>{{mensaje}}</span>
                <mdb-btn color="primary" @click.native="alertaModal = false">ACEPTAR</mdb-btn>
            </mdb-modal-body>
        </mdb-modal>

    </default-layout>
</template>

<script>
import ApiService from '../../services/ApiService';
import GoogleMap from '../../components/GoogleMap.vue';
export default {
  components: { GoogleMap },
    data(){
        return{
            dataForm: {
                monumento: null,
                year1: null,
                year2: null,
                year3: null,
                tipo: null,
                constructionYear: null,
                direccion: null,
                codigoPostal:null,
                coordenadas: { lat: null, lng: null},
                localidad: null,
                provincia: null,
                provinciaCode: null,
                ciudad: null,
                nombreContacto: null,
                apellidoContacto: null,
                telefonoContacto: null,
                emailContacto: null,
                cargo: null,
                horarioContacto: null,
                observaciones: null,
                comunidadAutonoma: null,
                emailDeAcceso: null,
                emailDeAccesoConfirmacion: null,
                municipio: null,
                municipioCode: null,
                meanTicket: null
            },
            tiposDeMonumentos:[
                { text:"Castillos", value:"Castillos"},
                { text:"Palacios", value:"Palacios"},
                { text:"Palacios Reales", value:"Palacios Reales"},
                { text:"Recintos Amurallados", value:"Recintos Amurallados"},
                { text:"Otros", value:"Otros"},
            ],
            horariosOptions:[
                {text:"Mañana", value:0},
                {text:"Tarde", value:1},
            ],
            validation: {
                zipCode: {
                    valid: false,
                    validated: false
                },
                aceptoTerminos: {
                    valid: true, 
                    validated: false
                },
                comunidad: {
                    valid: true,
                    validated: false
                },
                provincia: {
                    valid: true,
                    validated: false
                }
            },
            aceptoTerminos: false,
            enviando: false,
            alertaModal: false,
            mensaje: null,
            comunidadesAutonomas: [],
            provincias: []
        }
    },
    methods:{
        setCoordinates( evt ){
            console.log( evt );
            this.dataForm.coordenadas.lat = evt.lat;
            this.dataForm.coordenadas.lng = evt.lng;
        },
        goToHome(){
            let $vm = this;
            $vm.$router.push('/')
        },
        tipoDeMonumentoSelected(evt){
            this.dataForm.tipo = evt;
        },
        horarioSelected(evt){
            this.dataForm.horarioContacto = evt
        },
        localidadSelected(evt){
            this.dataForm.localidad = evt
        },
        provinciaSelected(evt){
            this.dataForm.provincia = evt;
        },
        async validarRegistro(evt){
            evt.target.classList.add('was-validated');
            this.validate('zipCode');
            this.validate('aceptoTerminos');            
            this.$refs.tipoSelect.validate();
            this.$refs.horarioSelect.validate()

            this.validate('comunidadSelect');
            this.validate('provinciaSelect');
            // this.$refs.comunidadSelect.validate()
            // this.$refs.provinciaSelect.validate()
            // this.$refs.aceptoTerminos.validate();

            console.log("Acepto términos: ", this.aceptoTerminos);

            const { monumento, year1, year2, year3, tipo, constructionYear,
                direccion, codigoPostal, nombreContacto, apellidoContacto,
                telefonoContacto, emailContacto, cargo, horarioContacto, emailDeAcceso, emailDeAccesoConfirmacion } = this.dataForm;

            let correoDeAccesoCorrecto = false;

            const meanTicket = parseFloat(this.dataForm.meanTicket.replace(",", "."));

            const correoOrigianl = emailDeAcceso.trim();
            const correoOriginalConfirmacion = emailDeAccesoConfirmacion.trim();

            if( correoOrigianl ){
                if( correoOrigianl == correoOriginalConfirmacion ){
                    correoDeAccesoCorrecto = true;
                }
            }

            if( monumento && year1 && year2 && year3 && tipo && constructionYear && direccion
            && codigoPostal && nombreContacto && apellidoContacto && meanTicket != null
            && telefonoContacto && emailContacto && cargo && horarioContacto != null
            && this.aceptoTerminos && correoDeAccesoCorrecto ){

                console.log( "aquí" )

                this.dataForm.meanTicket = meanTicket;
                this.enviando = true;
                const response = await this.enviarRegistro( this.dataForm );
                const mensaje = response.data.codigo;
                const error = response.data.error;
                if( error ){
                    this.mensaje = error;
                }
                else{
                    this.mensaje = `Su solicitud fue recibida con éxito, con el código: ${mensaje}. Le agradecemos su interes, pronto nos comunicaremos con usted`
                }

                this.enviando = false;
                this.alertaModal = true;                    
            }
            else{
                console.log("Hay un dato que no se envía correctamente")
            }


        },
        async validate(key, value){
            if(key === "zipCode"){

                console.log("Aquí")
                this.dataForm.codigoPostal = this.dataForm.codigoPostal.trim();
                if( this.dataForm.codigoPostal.length == 5  ){
                    let isValid = this.validarCodigoPostal( this.dataForm.codigoPostal )
                    if( isValid ){
                        await this.setComunityAndProvince( this.dataForm.codigoPostal );
                        this.validation['zipCode'].valid = true;
                    }
                    else{
                        this.validation['zipCode'].valid = true;
                        this.clearCodigoPostal();
                    }
                }
                else{
                    this.validation['zipCode'].valid = false;
                }
                this.validation['zipCode'].validated = true;
            }
            else if( key === "aceptoTerminos" ){
                if(this.aceptoTerminos != true){
                    this.validation['aceptoTerminos'].valid = false;
                }
                else{
                    this.validation['aceptoTerminos'].valid = true;
                }
                this.validation['aceptoTerminos'].validated = true;
            }
            else if( key == 'comunidad'){
                if( this.dataForm.comunidadAutonoma != null && this.dataForm.comunidadAutonoma != ""){
                    this.validation['comunidad'].valid = true;
                }
                else{
                    this.validation['comunidad'].valid = false;
                }
                this.validation['comunidad'].validated = true;
            }
            else if( key == 'provincia'){
                if( this.dataForm.provincia != null && this.dataForm.provincia != ""){
                    this.validation['provincia'].valid = true;
                }
                else{
                    this.validation['provincia'].valid = false;
                }
                this.validation['provincia'].validated = true;
            }

        },
        // Enviamos el registro al backend para que notifique por email y aparezca en el admin
        async enviarRegistro(data){

            const resultado = await ApiService.sendMonumentRequest( JSON.stringify(data) )

            // console.log( resultado.data );

            return resultado;
            // evt.target.classList.add('was-validated');
            // console.log(this.dataForm)

            // if( this.dataForm.apellidoContacto != null && this.year){
            //     console.log("No se ha validado correctamente")
            // }
        },

                /**
         * Se utiliza para determinar si el código postal es válido o no
         * @param {String} codigo - Código postal a ser validado
         */
         validarCodigoPostal(codigo){
            // Convertimos el string que recibimos a un número, para poder corroborar que el
            // input sea un número válido
            let codeToNumber = parseInt( codigo );

            // Si el resultado nos indica que es falso, entonces se introdujeron letras, no solo números
            let isNumber = !isNaN(codeToNumber);

            let isValid = false;

            if( isNumber ){
                if(codeToNumber >= 1000 ){
                    isValid = true;
                }
            }
            return isValid;
        },
        /**
         * Limpiamos las variables de provincia y comunidad
         */
        clearCodigoPostal(){
            this.dataForm.provincia = null;
            this.dataForm.comunidadAutonoma = null;
        },

        /**
         * Se utiliza el código postal para determinar la provincia y la comunidad
         * @param {String} codigoPostal - Código postal que introdujo el usuario
         */
        async setComunityAndProvince(codigoPostal){
            let comunityCode = codigoPostal.substr(0, 2);

            try{
                const municipioResult = await ApiService.getMunicpio( codigoPostal.trim() );
                const municipio = municipioResult.data;
                this.dataForm.municipio = municipio.municipio_nombre;
                this.dataForm.municipioCode = municipio.municipio_id;
            }
            catch(err){
                console.log(err);
            }

            this.provincias.forEach( provincia => {
                if(provincia.value == comunityCode){
                    provincia.selected = true;
                    // this.dataForm.provinciaCliente = provincia.text;
                    this.dataForm.provinciaCode = provincia.value;
                    this.dataForm.provincia = provincia.text;
                    this.dataForm.comunidadAutonoma = provincia.comunidad;

                    this.comunidadesAutonomas.forEach( comunidad => {
                        if( comunidad.value === provincia.comunidad ){
                            comunidad.selected = true;
                        }
                        else{
                            comunidad.selected = false;
                        }
                    })
                }
                else{
                    provincia.selected = false;
                }
            } )

        },

    },
    created(){
        this.comunidadesAutonomas = [...this.$store.getters.comunidadesAutonomas]
        this.provincias = [...this.$store.getters.provincias];
    }
}
</script>