var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("br"),
      _c(
        "mdb-container",
        [
          _c("GmapAutocomplete", {
            staticClass: "mb-4",
            staticStyle: { width: "100%" },
            on: { place_changed: _vm.setPlace },
          }),
          _c(
            "GmapMap",
            {
              staticStyle: { width: "100%", height: "400px" },
              attrs: { center: _vm.center, zoom: _vm.zoom },
            },
            _vm._l(_vm.markers, function (m, index) {
              return _c("GmapMarker", {
                key: index,
                attrs: { position: m.position, draggable: true },
                on: {
                  click: function ($event) {
                    _vm.center = m.position
                  },
                  dragend: (evt) => _vm.setCoordinates(evt),
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h5", [_vm._v("Busca el monumento en el mapa")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }