var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "spainhn-solid-red-background d-flex",
          staticStyle: { "min-height": "5vh", width: "100%" },
        },
        [
          _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
                attrs: { role: "button" },
                on: { click: _vm.goToHome },
              },
              [_vm._v("Spain Heritage Network >> ")]
            ),
            _c(
              "p",
              { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
              [_vm._v(_vm._s(_vm.$t("default.ACCESS_SOCIOS_REGISTER")) + " ")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container-fluid px-md-1 px-lg-4 px-1 py-5",
          style: {
            padding: "0 0",
            backgroundImage: `url(${require("@/assets/home-bg.jpg")})`,
            backgroundSize: "cover",
            "background-repeat": "no-repeat",
            "background-position": "right",
            "min-height": "80vh",
          },
        },
        [
          _c(
            "div",
            { staticClass: "container bg-white shadow px-sm-0 px-md-5 pt-3" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", { staticClass: "spainhn-subtitle" }, [
                    _vm._v("Registro de monumentos"),
                  ]),
                ]),
              ]),
              _c(
                "form",
                {
                  staticClass: "pt-1 pb-5 mb-5",
                  attrs: { novalidate: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.validarRegistro.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Datos de monumento"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Nombre de monumento *",
                            type: "text",
                            name: "nombreMonumento",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.monumento,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "monumento", $$v)
                            },
                            expression: "dataForm.monumento",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "mdb-container",
                          {},
                          [
                            _c("mdb-select", {
                              ref: "tipoSelect",
                              attrs: {
                                color: "danger",
                                label: "Tipo de monumento",
                                name: "tipoMonumento",
                                required: "",
                                outline: "",
                                validation: "",
                                invalidFeedback: "Campo obligatorio *",
                              },
                              on: {
                                getValue: (evt) => (_vm.dataForm.tipo = evt),
                              },
                              model: {
                                value: _vm.tiposDeMonumentos,
                                callback: function ($$v) {
                                  _vm.tiposDeMonumentos = $$v
                                },
                                expression: "tiposDeMonumentos",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Año de construcción",
                            type: "number",
                            name: "constYear",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.constructionYear,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "constructionYear", $$v)
                            },
                            expression: "dataForm.constructionYear",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row w-100 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Estadísticas"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "No. Visitas 2019",
                            type: "number",
                            name: "visitas1",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.year1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "year1", $$v)
                            },
                            expression: "dataForm.year1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "No. Visitas 2020",
                            type: "number",
                            name: "visitas2",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.year2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "year2", $$v)
                            },
                            expression: "dataForm.year2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "No. Visitas 2021",
                            type: "number",
                            name: "visitas3",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.year3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "year3", $$v)
                            },
                            expression: "dataForm.year3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Ticket medio",
                            name: "ticketMedio",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                            small:
                              "¿De cuánto es el la venta, promedio, por cada ticket?",
                          },
                          model: {
                            value: _vm.dataForm.meanTicket,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "meanTicket", $$v)
                            },
                            expression: "dataForm.meanTicket",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row w-100 mt-2 gy-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 spainhn-divider mb-2 text-start",
                        },
                        [
                          _c("p", { staticClass: "fw-bold" }, [
                            _vm._v("Ubicación del monumento"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "Dirección",
                              type: "text",
                              required: "",
                              outline: "",
                              invalidFeedback:
                                "Proporcione una dirección válida",
                            },
                            model: {
                              value: _vm.dataForm.direccion,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "direccion", $$v)
                              },
                              expression: "dataForm.direccion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c("mdb-input", {
                            ref: "zipCode",
                            attrs: {
                              label: "Código postal",
                              type: "text",
                              size: "5",
                              customValidation:
                                _vm.validation.zipCode.validated,
                              isValid: _vm.validation.zipCode.valid,
                              required: "",
                              outline: "",
                              invalidFeedback:
                                "Proporcione un Código postal válido",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.validate("zipCode")
                              },
                            },
                            model: {
                              value: _vm.dataForm.codigoPostal,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "codigoPostal", $$v)
                              },
                              expression: "dataForm.codigoPostal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            ref: "comunidadSelect",
                            attrs: {
                              required: "",
                              disabled: "",
                              outline: "",
                              label: "Comunidad Autónoma",
                            },
                            model: {
                              value: _vm.dataForm.comunidadAutonoma,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "comunidadAutonoma", $$v)
                              },
                              expression: "dataForm.comunidadAutonoma",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-12" },
                            [
                              _c("mdb-input", {
                                ref: "provinciaSelect",
                                attrs: {
                                  required: "",
                                  disabled: "",
                                  outline: "",
                                  label: "Provincia",
                                },
                                model: {
                                  value: _vm.dataForm.provincia,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "provincia", $$v)
                                  },
                                  expression: "dataForm.provincia",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-12" },
                            [
                              _c("mdb-input", {
                                ref: "municipioSelect",
                                attrs: {
                                  required: "",
                                  disabled: "",
                                  outline: "",
                                  label: "Municipio",
                                  placeholder: " ",
                                },
                                model: {
                                  value: _vm.dataForm.municipio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "municipio", $$v)
                                  },
                                  expression: "dataForm.municipio",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-12 text-start mb-0" }, [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Coordenadas "),
                          _c(
                            "span",
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "fa-info-circle",
                                  "data-mdb-toggle": "tooltip",
                                  "data-mdb-placement": "right",
                                  title:
                                    "Puede encontrar esta información buscando el monumento en google maps",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("google-map", {
                        on: { markerAdded: _vm.setCoordinates },
                      }),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "latitud",
                              type: "text",
                              outline: "",
                              required: "",
                            },
                            model: {
                              value: _vm.dataForm.coordenadas.lat,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm.coordenadas, "lat", $$v)
                              },
                              expression: "dataForm.coordenadas.lat",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "longitud",
                              type: "text",
                              outline: "",
                              required: "",
                            },
                            model: {
                              value: _vm.dataForm.coordenadas.lng,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm.coordenadas, "lng", $$v)
                              },
                              expression: "dataForm.coordenadas.lng",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Datos de contacto"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            type: "text",
                            label: "Nombre",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.nombreContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "nombreContacto", $$v)
                            },
                            expression: "dataForm.nombreContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Apellidos",
                            type: "text",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.apellidoContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "apellidoContacto", $$v)
                            },
                            expression: "dataForm.apellidoContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Teléfono",
                            type: "tel",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.telefonoContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "telefonoContacto", $$v)
                            },
                            expression: "dataForm.telefonoContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Email",
                            type: "email",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.emailContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "emailContacto", $$v)
                            },
                            expression: "dataForm.emailContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Cargo",
                            type: "text",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.cargo,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "cargo", $$v)
                            },
                            expression: "dataForm.cargo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-select", {
                              ref: "horarioSelect",
                              attrs: {
                                label: "Horario de contacto",
                                color: "danger",
                                validation: "",
                                resetBtn: "",
                                required: "",
                                outline: "",
                              },
                              on: {
                                getValue: (evt) =>
                                  (_vm.dataForm.horarioContacto = evt),
                              },
                              model: {
                                value: _vm.horariosOptions,
                                callback: function ($$v) {
                                  _vm.horariosOptions = $$v
                                },
                                expression: "horariosOptions",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Datos administrativos"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            type: "email",
                            label: "Email",
                            placeholder:
                              "Dirección de correo que se utilizará para acceder a su panel de administración",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.emailDeAcceso,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "emailDeAcceso", $$v)
                            },
                            expression: "dataForm.emailDeAcceso",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Confirma Email",
                            placeholder: "Repita la dirección de correo",
                            type: "email",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.emailDeAccesoConfirmacion,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "emailDeAccesoConfirmacion",
                                $$v
                              )
                            },
                            expression: "dataForm.emailDeAccesoConfirmacion",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "col-12 spainhn-divider mb-2 text-start",
                    }),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            type: "textarea",
                            outline: "",
                            label: "Comentarios",
                          },
                          model: {
                            value: _vm.dataForm.observaciones,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "observaciones", $$v)
                            },
                            expression: "dataForm.observaciones",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Terminos y condiciones"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 text-start mt-2 my-auto" },
                      [
                        _c(
                          "mdb-input",
                          {
                            staticClass: "my-auto d-flex w-100",
                            attrs: {
                              type: "checkbox",
                              id: "terminos-condiciones",
                              name: "terminos-condiciones",
                              required: "",
                            },
                            model: {
                              value: _vm.aceptoTerminos,
                              callback: function ($$v) {
                                _vm.aceptoTerminos = $$v
                              },
                              expression: "aceptoTerminos",
                            },
                          },
                          [
                            _c("p", { staticClass: "my-auto" }, [
                              _vm._v("Acepto el "),
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://spainheritagenetwork.com/privacidad-cookies",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "tratamiento de mis datos personales"
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(
                                " para poder acceder al Módulo de Socios*"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "small",
                          {
                            staticClass: "text-danger fw-bold",
                            class: {
                              "d-none":
                                _vm.validation.aceptoTerminos.valid === true,
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "inverted",
                              attrs: { icon: "fa-circle-exclamation" },
                            }),
                            _vm._v(" Campo obligatorio* "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button my-2",
                      attrs: {
                        color: "indigo",
                        size: "lg",
                        type: "submit",
                        disabled: _vm.enviando,
                      },
                    },
                    [
                      _vm._v("Enviar solicitud "),
                      _c(
                        "span",
                        { class: { "d-none": !_vm.enviando } },
                        [_c("b-spinner")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "mdb-modal",
        {
          attrs: {
            frame: "",
            position: "top",
            direction: "top",
            show: _vm.alertaModal,
          },
          on: {
            close: function ($event) {
              _vm.alertaModal = false
            },
          },
        },
        [
          _c(
            "mdb-modal-body",
            { staticClass: "text-center" },
            [
              _c("span", [_vm._v(_vm._s(_vm.mensaje))]),
              _c(
                "mdb-btn",
                {
                  attrs: { color: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.alertaModal = false
                    },
                  },
                },
                [_vm._v("ACEPTAR")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }